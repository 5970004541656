<template>
  <div class="about screen margin-bottom-tab padding-top-lg">
    <div class="flex align-center margin-bottom padding-lr-sm">
      <van-icon size="small" name="arrow-left" />
      <div class="back text-df text-bold" @click="goBack">返回</div>
    </div>
    <!-- TODO 如果开始评价了,要不能编辑  -->
    <div class="flex align-center padding-top padding-lr-sm">
      <div class="title text-lg text-bold margin-bottom">维度设置</div>
    </div>
    <van-sticky>
      <div class="screen">
        <van-tabs v-model="active" class="solid-bottom" @click="onClick">
          <van-tab title="自我评价"></van-tab>
          <van-tab title="工作互评"></van-tab>
          <van-tab title="对管理者评价"></van-tab>
        </van-tabs>
      </div>
    </van-sticky>
    <div class="flex justify-between align-center padding-sm padding-top">
      <div class="flex align-center">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df margin-right-xs">考核指标</div>
        <div class="title text-df">{{ percent }}%</div>
      </div>
      <van-button round type="info" size="small" @click="showDialogOne"
        >添加维度</van-button
      >
    </div>
    <div v-for="(item, index) in dimensionZero" :key="'type_one_a' + index">
      <div
        v-if="item.is_delete == 0"
        class="padding-sm flex align-center flex-direction justify-between"
      >
        <div class="response flex justify-between align-center">
          <div class="flex justify-start align-center">
            <div class="text-df margin-right-sm">维度标题</div>
            <div class="flex align-center justify-start">
              <van-stepper
                v-model="item.percent"
                step="0.5"
                :decimal-length="1"
                @change="onChange"
              />
              <div class="per text-df">%</div>
            </div>
          </div>
          <van-button
            icon="delete"
            type="danger"
            size="mini"
            @click="deleteDimension(item, index, 0)"
          />
        </div>
        <div class="response flex flex-direction align-center">
          <van-field
            class="border"
            v-model="item.title"
            placeholder="请输入维度标题"
          />
        </div>
        <div class="response text-df text-left margin-top-sm">维度介绍</div>
        <van-field
          class="border"
          v-model="item.intro"
          rows="1"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="请输入维度下面的介绍"
          show-word-limit
        />
        <div class="flex response align-center justify-start padding-sm">
          <div class="text-df margin-right-xs">是否需要说明</div>
          <van-switch
            active-value="1"
            inactive-value="0"
            v-model="item.need_reason"
            size="20px"
          />
        </div>
        <div class="response" v-if="item.need_reason == 1">
          <div class="response text-df text-left margin-top-sm">
            输入框中的默认语句
          </div>
          <van-field
            class="border"
            v-model="item.placeholder"
            rows="2"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="输入框中的默认语句"
            show-word-limit
          />
        </div>
        <div class="response" v-if="active == 1 || active == 2">
          <div class="flex align-center justify-start padding-tb-sm">
            <div class="text-df margin-right-xs">关联显示自我评价中的维度</div>
            <van-switch
              active-value="1"
              inactive-value="0"
              v-model="item.need_connect"
              size="20px"
            />
          </div>
          <van-cell
            class="cell-border"
            v-if="item.need_connect == 1"
            title="显示"
            is-link
            :value="
              item.evaluate_item_title != null
                ? item.evaluate_item_title
                : '选择'
            "
            @click="showConnectPicker(index)"
          />
        </div>
      </div>
      <van-divider />
    </div>

    <div class="flex justify-between align-center padding-sm padding-top">
      <div class="flex align-center">
        <div class="point bg-red margin-right-xs"></div>
        <div class="title text-df">附加</div>
      </div>
      <van-button round type="info" size="small" @click="showDialogTwo"
        >添加维度</van-button
      >
    </div>
    <div v-for="(item, index) in dimensionOne" :key="'type_one_b' + index">
      <div
        class="padding-sm flex align-center flex-direction justify-between"
        v-if="item.is_delete == 0"
      >
        <div class="response flex justify-between align-center">
          <div class="text-df">维度标题</div>
          <van-button
            icon="delete"
            type="danger"
            size="mini"
            @click="deleteDimension(item, index, 1)"
          />
        </div>
        <div class="response flex flex-direction align-center">
          <van-field class="border" v-model="item.title" placeholder="标题" />
        </div>
        <div class="response text-df text-left margin-top-sm">维度介绍</div>
        <van-field
          class="border"
          v-model="item.intro"
          rows="1"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="请输入维度下面的介绍"
          show-word-limit
        />
        <div class="flex response align-center justify-start padding-sm">
          <div class="text-df margin-right-xs">是否需要说明</div>
          <van-switch
            active-value="1"
            inactive-value="0"
            v-model="item.need_reason"
            size="20px"
          />
        </div>
        <div class="response" v-if="item.need_reason == 1">
          <div class="response text-df text-left margin-top-sm">
            输入框中的默认语句
          </div>
          <van-field
            class="border"
            v-model="item.placeholder"
            rows="2"
            autosize
            type="textarea"
            maxlength="50"
            placeholder="输入框中的默认语句"
            show-word-limit
          />
        </div>
        <div class="flex response align-center justify-start padding-sm">
          <div class="text-df margin-right-xs">是否需要选人</div>
          <van-switch
            active-value="1"
            inactive-value="0"
            v-model="item.need_choose"
            size="20px"
          />
        </div>
      </div>
      <van-divider />
    </div>
    <div v-if="active == 0">
      <div class="flex justify-between align-center padding-sm padding-top">
        <div class="flex align-center">
          <div class="point bg-red margin-right-xs"></div>
          <div class="title text-df">邀请</div>
        </div>
      </div>
      <div class="padding-sm flex align-center flex-direction justify-between">
        <div
          class="response flex align-center justify-between margin-bottom-xs"
        >
          <div class="flex justify-start flex-direction">
            <div class="title text-df">是否开启邀请</div>
          </div>
          <van-switch
            active-value="1"
            inactive-value="0"
            v-model="invite_user"
            size="20px"
          />
        </div>
        <div class="response text-sm text-left text-gray">
          邀请不超过三名同事对自己进行工作互助评价, 不能邀请您的上级!
        </div>
      </div>
    </div>
    <van-divider />
    <div>
      <van-button type="info" size="normal" @click="saveEvaluateItems"
        >确定保存</van-button
      >
    </div>
    <van-dialog
      v-model="dialog_show"
      title="添加考核指标维度"
      show-cancel-button
      @confirm="onConfirm"
      @cancel="onCancel"
    >
      <div class="flex">
        <van-form>
          <van-field
            v-model="dialogForm.title"
            name="标题"
            label="标题"
            placeholder="请输入维度标题"
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            v-model="dialogForm.intro"
            name="介绍"
            label="介绍"
            placeholder="请输入维度介绍"
          />
          <van-field name="stepper" label="百分比">
            <template #input>
              <van-stepper v-model="dialogForm.percent" /> %
            </template>
          </van-field>
          <van-field name="switch" label="是否需要说明">
            <template #input>
              <van-switch
                active-value="1"
                inactive-value="0"
                v-model="dialogForm.need_reason"
                size="20"
              />
            </template>
          </van-field>
          <van-field
            v-if="dialogForm.need_reason == true"
            v-model="dialogForm.placeholder"
            name="说明文字"
            label="说明文字"
            placeholder="请输入说明框内的说明文字"
          />
        </van-form>
      </div>
    </van-dialog>
    <van-dialog
      v-model="dialog_show_two"
      title="添加附加维度"
      show-cancel-button
      @confirm="onConfirm"
      @cancel="onCancel"
    >
      <div class="flex">
        <van-form>
          <van-field
            v-model="dialogForm.title"
            name="标题"
            label="标题"
            placeholder="请输入维度标题"
            :rules="[{ required: true, message: '请输入维度标题' }]"
          />
          <van-field
            v-model="dialogForm.intro"
            name="介绍"
            label="介绍"
            placeholder="请输入维度介绍"
            :rules="[{ required: true, message: '请输入维度介绍' }]"
          />
          <van-field name="switch" label="是否需要选人">
            <template #input>
              <van-switch
                active-value="1"
                inactive-value="0"
                v-model="dialogForm.need_choose"
                size="20"
              />
            </template>
          </van-field>
          <van-field name="switch" label="是否需要说明">
            <template #input>
              <van-switch
                active-value="1"
                inactive-value="0"
                v-model="dialogForm.need_reason"
                size="20"
              />
            </template>
          </van-field>
          <van-field
            v-if="dialogForm.need_reason == true"
            v-model="dialogForm.placeholder"
            name="说明文字"
            label="说明文字"
            placeholder="请输入说明框内的说明文字"
          />
        </van-form>
      </div>
    </van-dialog>
    <van-tabbar v-model="tab_active" route>
      <van-tabbar-item icon="bars" replace to="/result">结果</van-tabbar-item>
      <van-tabbar-item icon="setting" replace to="/settings"
        >维度</van-tabbar-item
      >
      <van-tabbar-item icon="user" replace to="/user">人员</van-tabbar-item>
    </van-tabbar>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="cancelPicker"
        @confirm="onConnectConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import {
  getEvaluationByTypeAction,
  getResultSettingsAction,
  saveEvaluateItemsAction,
} from "../api/evaluation.js";

import {
  Tab,
  Tabs,
  Divider,
  Cell,
  CellGroup,
  Field,
  Button,
  Switch,
  Stepper,
  Sticky,
  Dialog,
  Form,
  Icon,
  Popup,
  Picker,
} from "vant";

Vue.use(Picker);
Vue.use(Popup);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Divider);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(Button);
Vue.use(Divider);
Vue.use(Switch);
Vue.use(Stepper);
Vue.use(Sticky);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Icon);
export default {
  name: "Home",
  components: {
    // Button,
    // DropdownMenu,
    // Popover,
    // BizCalendarChooseDateTime,
    // BizContactComplexPicker,
  },
  methods: {
    showConnectPicker(index) {
      this.evaluateIndex = index;
      this.showPicker = true;
    },
    cancelPicker() {
      this.showPicker = false;
    },
    onConnectConfirm(value, index) {
      let dimensionZero = this.dimensionZero;
      let evaluateIndex = this.evaluateIndex;
      let dimensionZeroTemp = this.dimensionZeroTemp;
      dimensionZero[evaluateIndex].evaluate_item_id =
        dimensionZeroTemp[index].id;
      dimensionZero[evaluateIndex].evaluate_item_title = value;
      this.dimensionZero = dimensionZero;
      this.showPicker = false;
    },
    onChange() {
      let dimensionZero = this.dimensionZero;
      let percent = dimensionZero.reduce((sum, item) => {
        if (item.is_delete === 0) {
          return sum + Number(item.percent);
        }
        return sum;
      }, 0);

      if (percent > 100) {
        this.$message({
          type: "error",
          message: "百分比之和不能超过100%",
        });
        return false;
      }
      this.percent = percent;
    },
    saveEvaluateItems() {
      let result_id = this.result_id;
      let dimensionZero = this.dimensionZero;
      let percent = dimensionZero.reduce((sum, item) => {
        if (item.is_delete === 0) {
          return sum + Number(item.percent);
        }
        return sum;
      }, 0);
      if (percent > 100 || percent < 100) {
        this.$message({
          type: "error",
          message: "百分比之和必须为100",
        });
        return false;
      }
      let dimensionOne = this.dimensionOne;
      let invite_user = this.invite_user;
      let dimensionData = dimensionZero.concat(dimensionOne);
      let that = this;
      saveEvaluateItemsAction({
        dimensionData: dimensionData,
        invite_user: invite_user,
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        if (errno == 0) {
          that.$message({
            type: "success",
            message: "更新成功",
          });
          that.getEvaluationByType();
        }
      });
    },
    onClick(index) {
      let oldIndex = this.oldIndex;
      if (index != oldIndex) {
        this.getEvaluationByType();
        this.oldIndex = index;
        if (index == 1) {
          this.getEvaluationColumns();
        }
      }
    },
    showDialogOne() {
      this.dialogForm = {};
      this.dimension = 0;
      this.dialog_show = true;
    },
    showDialogTwo() {
      this.dialogForm = {};
      this.dimension = 1;
      this.dialog_show_two = true;
    },
    onConfirm() {
      let dimension = this.dimension;
      let dialogForm = this.dialogForm;
      let result_id = this.result_id;
      let type = this.active;
      dialogForm.is_delete = 0;
      dialogForm.type = type;
      dialogForm.result_id = result_id;
      dialogForm.dimension = dimension;
      if (dimension == 0) {
        let dimensionZero = this.dimensionZero;
        dimensionZero.push(dialogForm);
        this.dimensionZero = dimensionZero;
      } else {
        let dimensionOne = this.dimensionOne;
        dimensionOne.push(dialogForm);
        this.dimensionOne = dimensionOne;
      }
    },
    onCancel() {},
    deleteDimension(item, index, dimen) {
      let dimensionData = [];
      dimen == 0
        ? (dimensionData = this.dimensionZero)
        : (dimensionData = this.dimensionOne);
      let that = this;
      Dialog.confirm({
        title: "提示",
        message: "确定要删除这个维度?",
      })
        .then(() => {
          if (item.id > 0) {
            dimensionData[index].is_delete = 1;
          } else {
            dimensionData.splice(index, 1);
          }

          if (dimen == 0) {
            let percent = dimensionData.reduce((sum, item) => {
              if (item.is_delete === 0) {
                return sum + Number(item.percent);
              }
              return sum;
            }, 0);
            that.percent = percent;
          }
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    goBack() {
      this.$router.push("/");
    },
    getEvaluationByType() {
      let type = this.active;
      let result_id = this.result_id;
      let that = this;
      that.dimensionZero = [];
      that.dimensionOne = [];
      that.percent = 0;
      getEvaluationByTypeAction({
        type: type,
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          if (data.length > 0) {
            that.dimensionZero = data.filter((item) => item.dimension == 0);
            that.dimensionOne = data.filter((item) => item.dimension == 1);
            let arr = that.dimensionZero;
            that.percent = arr.reduce((sum, item) => {
              return sum + item.percent;
            }, 0);
            if (type == 0) {
              that.getEvaluationColumns();
            }
          }
        }
      });
    },
    getEvaluationColumns() {
      let result_id = this.result_id;
      let that = this;
      let columns = [];
      getEvaluationByTypeAction({
        type: 0,
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          if (data.length > 0) {
            let dimensionZero = data.filter((item) => item.dimension == 0);
            that.dimensionZeroTemp = dimensionZero;
            dimensionZero.map((item) => {
              columns.push(item.title);
            });
            that.columns = columns;
          }
        }
      });
    },
    getResultSettings() {
      let result_id = this.result_id;
      getResultSettingsAction({
        result_id: result_id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          this.invite_user = data.invite_user;
        }
      });
    },
  },
  mounted() {
    let result_id = localStorage.getItem("result_id");
    this.result_id = result_id;
    this.getEvaluationByType();
    this.getResultSettings();
  },
  data() {
    return {
      result_id: 0,
      invite_user: 0,
      dimensionZero: [],
      dimensionZeroTemp: [],
      dimensionOne: [],
      active: 0,
      dialog_show: false,
      dialog_show_two: false,
      dialogForm: {},
      dimension: 0,
      tab_active: 1,
      percent: 0,
      showPicker: false,
      columns: [], // 互评的列表
      evaluateIndex: 0, // 选择了那个自评index
    };
  },
};
</script>

<style scoped lang="scss">
.cell-border {
  border: 1px solid #e4e4e4;
}
.back {
  cursor: pointer;
}
.screen {
  max-width: 600px;
  margin: auto;
}
::v-deep .van-cell__title {
  display: flex;
  justify-content: flex-start;
}
.border {
  margin-top: 8px;
  border: 1px solid #d4d4d4;
}
</style>